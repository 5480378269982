<template>
  <div class="bg-white">
    <main>
      <all-recruiters></all-recruiters>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import AllRecruiters from '../components/recruiters/AllRecruiters.vue';

export default {
  name: 'Recruiters',
  components: {
    AllRecruiters,
  }
}
</script>
